<script>
export default {
  name: 'LoadingSkeleton'
}
</script>

<template>
  <svg
    role="img"
    width="476"
    height="124"
    aria-labelledby="loading-aria"
    viewBox="0 0 476 124"
    preserveAspectRatio="none"
  >
    <title
      id="loading-aria"
      aria-live="polite"
      aria-busy
    >Loading...</title>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      clip-path="url(#clip-path)"
      fill="url(#fill)"
    />
    <defs>
      <clipPath id="clip-path">
        <rect
          x="0"
          y="8"
          rx="3"
          ry="3"
          width="88"
          height="6"
        />
        <rect
          x="0"
          y="26"
          rx="3"
          ry="3"
          width="52"
          height="6"
        />
        <rect
          x="200"
          y="20"
          rx="3"
          ry="3"
          width="120"
          height="6"
        />
        <rect
          x="200"
          y="36"
          rx="3"
          ry="3"
          width="160"
          height="6"
        />
        <rect
          x="0"
          y="56"
          rx="3"
          ry="3"
          width="410"
          height="6"
        />
        <rect
          x="0"
          y="72"
          rx="3"
          ry="3"
          width="380"
          height="6"
        />
        <rect
          x="0"
          y="88"
          rx="3"
          ry="3"
          width="178"
          height="6"
        />
      </clipPath>
      <linearGradient id="fill">
        <stop
          offset="0.599964"
          stop-color="#f3f3f3"
          stop-opacity="1"
        >
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop
          offset="1.59996"
          stop-color="#ecebeb"
          stop-opacity="1"
        >
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop
          offset="2.59996"
          stop-color="#f3f3f3"
          stop-opacity="1"
        >
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
</template>

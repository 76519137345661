import Vue from 'vue'
import { resetMonkInspection, getMonkInspectionReport } from './queries'
import { withQuery } from '@maxsystems/client'

export default Vue.extend({
  data: () => ({
    appraisalId: null,
    report: null,
    error: false,
    init: false,
    actions: {
      fetchMonkInspectionReport: withQuery(getMonkInspectionReport),
      resetMonkInspection: withQuery(resetMonkInspection)
    }
  }),
  computed: {
    loading: ({ init, actions }) =>
      !init ||
      actions.fetchMonkInspectionReport.isPending() ||
      actions.resetMonkInspection.isPending()
  },
  methods: {
    async load (appraisalId) {
      this.init = false
      this.report = null
      this.appraisalId = null

      try {
        const { data, errors } = await this.actions.fetchMonkInspectionReport.fetch({ appraisalId })
        if (errors?.length) throw new Error('Failed to load report')
        this.report = data.monkInspectionReport
        this.appraisalId = appraisalId
      } catch {
        this.error = true
      } finally {
        this.init = true
      }
    },
    async reset () {
      await this.actions.resetMonkInspection.fetch({
        appraisalId: this.appraisalId,
        inspectionId: this.report.id
      })
      this.report = null
    }
  }
})

<script>
import dataSource from '../../data-source'
import { currency } from '@maxsystems/ui/vue/filters'
import ExternalLink from './components/ExternalLink.vue'
import LoadingSkeleton from '../LoadingSkeleton.vue'

import ACVGuarantee from './ACVGuarantee.vue'

import BaseCard from '../BaseCard'

export default {
  name: 'ACVWholesale',
  filters: {
    currency
  },
  components: {
    ACVGuarantee,
    BaseCard,
    ExternalLink,
    LoadingSkeleton
  },
  props: {
    /** Deprecated in favor of `id` */
    appraisal: {
      type: [Number, String],
      default: null
    },
    /** Appraisal or Inventory Id */
    id: {
      type: [Number, String],
      // @todo: once fully migrated, the id should be required
      default: null
    },
    type: {
      type: String,
      default: 'appraisal',
      validator: value => ['appraisal', 'inventory'].includes(value)
    },
    env: {
      type: String,
      default: null // it defaults to production when not specified
    }
  },
  computed: {
    adjustedAmount: () => dataSource.values?.valuation?.adjusted?.amount,
    highValue: () => dataSource.values?.valuation?.highValue?.amount,
    lowValue: () => dataSource.values?.valuation?.lowValue?.amount,
    guarantee: () => dataSource.guarantee,
    isLoading: () => dataSource.isLoadingWholesale,
    notAvailable: vm => !vm.highValue && !vm.lowValue && !vm.adjustedAmount && !vm.isLoading,
    vin: () => dataSource.values?.vehicle?.vin,
    odometer: () => dataSource.values?.vehicle?.odometer?.value,
    odometerValue: ({ odometer }) => odometer === null || odometer === undefined || odometer === 0 ? 1 : odometer,
    startDate: () => {
      const today = new Date()
      today.setDate(today.getDate() - 90)

      return today.toISOString().substring(0, 10)
    },
    linkEnv: ({ env }) => {
      if (env === 'dev') return 'dev.my.max.auto'
      if (env === 'stage') return 'stage.my.max.auto'

      return 'my.max.auto'
    },
    endDate: () => new Date().toISOString().substring(0, 10),
    marketReportLink: ({ startDate, endDate, odometerValue, vin }) =>
    `https://app.acvauctions.com/market-report?tab=vin&vin=${vin}&odometer=${odometerValue}&startDate=${startDate}&endDate=${endDate}`
  },
  mounted () {
    dataSource.setEnv(this.env)
    dataSource.setType(this.type)
    // @todo: remove fallback to appraisal id once fully migrated
    dataSource.setId(this.id || this.appraisal)
  },
  methods: {
    navigateToDisclosures () {
      const url = `https://${this.linkEnv}/appraisals/${this.appraisal}/disclosures`
      window.open(url, '_parent')
    }
  }
}
</script>

<template>
  <BaseCard class="acv-wholesale">
    <div class="acv-wholesale__header">
      <img
        :src="require('../ACVLogo.svg').default"
        alt="ACV Logo"
        width="72"
        height="32"
      >
      <span class="acv-wholesale__title">Wholesale</span>
    </div>
    <div
      v-if="isLoading"
      class="acv-wholesale__content acv-wholesale__content--loading"
      aria-busy="true"
      aria-live="polite"
      role="img"
      aria-label="Loading"
    >
      <div>
        <LoadingSkeleton
          width="100"
          height="18"
        />
        <LoadingSkeleton
          width="150"
          height="18"
        />
      </div>
      <div>
        <LoadingSkeleton
          width="100"
        />
        <LoadingSkeleton
          width="150"
        />
      </div>
      <div>
        <LoadingSkeleton
          width="100"
        />
        <LoadingSkeleton
          width="150"
        />
      </div>
    </div>
    <div
      v-else-if="notAvailable"
      ref="empty"
      class="acv-wholesale__content acv-wholesale__content--empty"
    >
      ACV Wholesale Not Available
    </div>
    <div
      v-else
      class="acv-wholesale__wrapper"
    >
      <div class="acv-wholesale__content">
        <div class="acv-wholesale__wholesale-range">
          <div class="acv-wholesale__range-title">
            Range
          </div>
          <div
            ref="range-values"
            class="acv-wholesale__range-values"
          >
            {{ lowValue | currency }} - {{ highValue | currency }}
          </div>
          <ExternalLink
            v-if="vin"
            :href="marketReportLink"
          >
            ACV Market Report
          </ExternalLink>
        </div>
        <div class="acv-wholesale__wholesale-valuation">
          <div class="acv-wholesale__item">
            <span
              ref="adjusted-amount"
              class="acv-wholesale__item-valuation"
              :class="{ 'has-amount': adjustedAmount }"
            >{{ adjustedAmount || '-' | currency }}</span>
          </div>
          <button
            v-if="type === 'appraisal'"
            class="item-action"
            @click="navigateToDisclosures"
          >
            Edit Condition
          </button>
        </div>
      </div>
      <ACVGuarantee
        v-if="guarantee.isEnabled"
        :loading="guarantee.isLoading"
        :value="guarantee.value"
      />
    </div>
  </BaseCard>
</template>

<style lang="scss">
.acv-wholesale {
  flex-flow: column;

  &__header {
    align-items: center;
    background-color: #FEF0E9;
    border-radius: 5px 5px 0 0;
    display: flex;
    height: 48px;
    padding: 0 16px;
  }

  &__title {
    margin: 0 6px 0 12px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin: 16px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    min-height: 60px;

    &--empty {
      align-items: center;
      color: rgb(0 0 0 / 60%);
      justify-content: center;
      margin: 16px;
    }

    &--loading {
      flex-direction: column;
      margin: 16px;

      div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
      }
    }
  }

  &__range-title {
    color: #55575C;
    font-size: 16px;
    margin-bottom: 6px;
  }

  &__range-values {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
  }

  &__wholesale-range {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &__wholesale-valuation {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    width: 50%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &-valuation {
      color: #212121;
      font-size: 24px;
      font-weight: 600;

      &.has-amount {
        text-align: right;
      }
    }
  }

  .item-action {
    background-color: #E9EEF7;
    border: none;
    border-radius: 20px;
    color: #1D3865;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 12px;

    &:hover {
      background-color: #D0DBED;
    }
  }
}
</style>

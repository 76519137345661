import wrap from '@vue/web-component-wrapper'

import { ACVRetail, ACVWholesale, CustomerOffer, DamageDetection } from './components'

if (window.Vue) {
  window.customElements.define('acv-retail', wrap(window.Vue, ACVRetail))
  window.customElements.define('acv-wholesale', wrap(window.Vue, ACVWholesale))
  window.customElements.define('customer-offer', wrap(window.Vue, CustomerOffer))
  window.customElements.define('damage-detection', wrap(window.Vue, DamageDetection))
}

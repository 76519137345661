import Vue from 'vue'
import { withQuery, auth, authData, client } from '@maxsystems/client'

import getAppraisal from './queries/getAppraisal.gql'
import updateAppraisal from './queries/updateAppraisal.gql'

export default new Vue({
  data: () => ({
    authData,
    actions: {
      fetchAppraisals: withQuery(getAppraisal),
      updateAppraisal: withQuery(updateAppraisal)
    },
    isAuthenticating: false,
    values: null,
    id: null
  }),

  computed: {
    amount: ({ values }) => values?.offer?.value?.amount,
    isLoggedIn: ({ authData: { isLoggedIn } }) => isLoggedIn,
    profile: ({ values }) => values?.customer?.profile,
    fetchingAppraisal: ({ actions }) => actions.fetchAppraisals.isPending()
  },

  watch: {
    id (value) {
      this.load(value)
    }
  },

  methods: {
    async isAuthenticated () {
      if (this.isLoggedIn) return true

      Vue.use(auth)
      auth.setup()

      return new Promise(resolve => {
        this.$watch('isLoggedIn', isLoggedIn => {
          if (!isLoggedIn) return
          resolve(true)
        }, { immediate: true })
      })
    },

    setEnv (env) {
      if (env) client.config({ env })
    },

    setId (id) {
      this.id = id
    },

    async load (id) {
      this.values = null

      try {
        const { data } = await this.actions.fetchAppraisals.fetch({ appraisalId: id })
        this.values = data?.appraisal || null
      } catch {} // errors are handled by presenting "Not Available" ui state
    },

    async updateAppraisalOffer (offerInputs) {
      // make sure we are authenticated
      await this.isAuthenticated()

      const input = { updateOffer: offerInputs }
      const { data, errors } = await this.actions.updateAppraisal.fetch({ input })

      if (errors?.length) throw new Error(errors[0].message || errors[0].extensions.code)

      // update local cache
      this.values.offer.value = data.updateAppraisal[0].appraisal.offer.value
    }
  }
})

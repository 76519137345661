<script>
export default {
  name: 'ExternalLink',
  props: {
    href: {
      type: String,
      required: true
    }
  }
}
</script>
<template>
  <a
    class="external-link"
    :href="href"
    rel="noopener"
    target="_blank"
  >
    <slot />
    <svg
      class="external-link__icon"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
    ><path
      fill="#1D3865"
      d="M9.5 10.083h-7a.585.585 0 0 1-.583-.583v-7c0-.32.262-.583.583-.583h2.917c.32 0 .583-.263.583-.584A.585.585 0 0 0 5.417.75h-3.5C1.269.75.75 1.275.75 1.917v8.166a1.17 1.17 0 0 0 1.167 1.167h8.166a1.17 1.17 0 0 0 1.167-1.167v-3.5A.585.585 0 0 0 10.667 6a.585.585 0 0 0-.584.583V9.5c0 .32-.262.583-.583.583zm-2.333-8.75c0 .321.262.584.583.584h1.51L3.936 7.242a.58.58 0 1 0 .823.823l5.325-5.326V4.25c0 .32.263.583.584.583.32 0 .583-.262.583-.583V.75h-3.5a.585.585 0 0 0-.583.583z"
    /></svg>
  </a>
</template>

<style lang="scss">
.external-link {
  align-items: center;
  color: #1D3865;
  display: flex;
  font-family: system-ui;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding: 4px 0;
  text-decoration: none;
  text-wrap: nowrap;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }

  &__icon {
    margin-left: 6px;
    transform: translateY(1px);
  }
}
</style>

import wrap from '@vue/web-component-wrapper'

import {
  ACVWidget,
  CustomerOffer,
  DamageDetection
} from './components'

if (window.Vue) {
  window.customElements.define('acv-widget', wrap(window.Vue, ACVWidget))
  window.customElements.define('customer-offer', wrap(window.Vue, CustomerOffer))
  window.customElements.define('damage-detection', wrap(window.Vue, DamageDetection))
}

<script>
import { currency } from '@maxsystems/ui/vue/filters'

import LoadingSkeleton from '../LoadingSkeleton.vue'

export default {
  name: 'ACVGuarantee',
  filters: {
    currency
  },
  components: {
    LoadingSkeleton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: null
    }
  }
}
</script>

<template>
  <div class="acv-guarantee">
    <div
      v-if="loading"
      class="acv-guarantee__loading"
      aria-busy="true"
      aria-live="polite"
      role="img"
      aria-label="Loading"
    >
      <LoadingSkeleton width="100" />
      <LoadingSkeleton width="150" />
    </div>
    <template v-else>
      <div class="acv-guarantee__header">
        <span class="acv-guarantee__title">ACV Guarantee</span>
      </div>
      <div class="acv-guarantee__body">
        <template v-if="value">
          <span class="acv-guarantee__value">
            {{ value || '-' | currency }}
          </span>
        </template>
        <template v-else>
          <span class="acv-guarantee__empty">Not Available</span>
          <a
            class="acv-guarantee__link"
            target="_blank"
            href="https://www.acvmax.com/support/acv-guarantee-in-max"
          >
            Learn More
          </a>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.acv-guarantee {
  border-top: #DCDFE8 1px solid;
  margin-top: 16px;
  padding-top: 16px;
  position: relative;

  &__loading {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    float: left;
    position: absolute;
  }

  &__title {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    margin-right: 6px;
  }

  &__body {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    float: right;
  }

  &__value {
    color: #212121;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  &__empty {
    color: #545454;
    margin-bottom: 4px;
  }

  &__link {
    color: #0082B3;
    font-size: 16px;
  }
}
</style>

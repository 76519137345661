<script>
import { currency, date, phone } from '@maxsystems/ui/vue/filters'

import BaseCard from '../BaseCard'
import BaseIcon from '../BaseIcon'
import OfferField from './OfferField'
import LoadingSkeleton from './LoadingSkeleton.vue'

import dataSource from '../../data-source'

export default {
  name: 'CustomerOffer',
  components: {
    BaseCard,
    BaseIcon,
    OfferField,
    LoadingSkeleton
  },
  filters: {
    currency,
    date,
    phone
  },
  provide: { dataSource },
  props: {
    appraisal: {
      required: true,
      type: [Number, String]
    },
    env: {
      type: String,
      default: null // it defaults to production when not specified
    }
  },

  computed: {
    amount: () => dataSource.amount && currency(dataSource.amount, { currency: dataSource.amount.currency, style: 'decimal' }),
    currencyCode: () => dataSource.amount?.currency,
    email: () => dataSource.profile?.email,
    hasData: () => typeof dataSource.amount !== 'undefined',
    initialOffer: () => dataSource.values?.offer?.original?.amount || dataSource.values?.offer?.value?.amount,
    name: () => dataSource.profile?.name,
    phone: () => dataSource.profile?.phone,
    updatedAt: () => dataSource.values.offer?.updatedAt
  },

  created () {
    dataSource.setEnv(this.env)
    /**
     * The dataSource handles both Appraisal and Inventory values.
     * Enforcing the type, since Customer Offer is related only to Appraisals.
     */
    dataSource.setType('appraisal')
    dataSource.setId(this.appraisal)
  }
}
</script>

<template>
  <BaseCard class="customer-offer">
    <div class="section heading">
      <BaseIcon
        type="customer"
        class="heading__icon"
        alt="Customer Offer Icon"
      />
      <p>
        Customer <br>
        Offer
      </p>
    </div>

    <div
      v-if="hasData"
      class="section current-offer"
    >
      <p class="title">
        Current Offer
      </p>
      <form class="offer-form">
        <div class="offer-form__input-container">
          <OfferField
            ref="offer"
            :currency="currencyCode"
            :appraisal="appraisal"
            :initial-offer="amount"
          />
        </div>
        <a
          class="offer-form__submit-button"
          :href="'https://appraisals.max.auto/' + appraisal + '/offer'"
          target="_blank"
          type="submit"
        >
          OFFER FORM
          <BaseIcon
            type="right-arrow"
            class="arrow"
            aria-hidden="true"
            alt="Go to Offer Form"
          />
        </a>
      </form>
    </div>
    <LoadingSkeleton
      v-else
      class="loading-skeleton"
    />
    <div
      v-if="hasData"
      class="section details"
    >
      <BaseIcon
        type="clear-car"
        class="details-icon"
        alt="ClearCar Logo"
      />
      <div class="details__columns">
        <div class="row">
          <div class="column column--label">
            <p>Initial Offer</p>
            <p>Offer Date</p>
          </div>
          <div class="column column--value">
            <p>{{ initialOffer | currency }}</p>
            <p>{{ updatedAt | date('shortDate') }}</p>
          </div>
        </div>

        <div class="row">
          <div class="column column--value">
            <p>{{ name }}</p>
            <p v-if="phone">
              {{ phone | phone }}
            </p>
            <p v-if="email">
              {{ email }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<style lang="scss">
.customer-offer {
  height: 136px;
  padding: 8px 20px;

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.heading {
      align-items: center;
      min-width: 104px;
      text-align: center;

      .heading__icon {
        height: 56px;
        width: 56px;
      }

      p {
        font-size: 16px;
        margin: 8px;
      }
    }

    &.current-offer {
      margin: 0 0 0 16px;
      min-width: 182px;

      .title {
        font-size: 14px;
        margin: 0 0 4px;
      }

      .offer-form {
        &__input-container {
          margin-bottom: 12px;
        }

        &__submit-button {
          align-items: center;
          background-color: #FFF;
          border: 1px solid #424242;
          border-radius: 4px;
          box-sizing: border-box;
          color: #000;
          display: inline-flex;
          font-size: 10px;
          justify-content: center;
          padding: 3px 6px;
          text-decoration: none;

          .arrow {
            height: 10px;
            margin: 0 4px;
            width: 10px;
          }
        }
      }
    }

    &.details {
      .details-icon {
        height: 18px;
        margin-bottom: 10px;
        width: 102px;
      }

      .details__columns {
        align-items: flex-start;
        display: flex;

        .row {
          display: flex;
          flex-direction: row;

          &:first-child {
            margin-right: 24px;
          }

          .column {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            &--label {
              color: rgb(0 0 0 / 60%);
              font-size: 11px;
            }

            &--value {
              color: rgb(0 0 0 / 87%);
              font-size: 13px;
              margin-left: 8px;
            }

            p {
              margin: 4px 0;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.loading-skeleton {
  margin-left: 18px;
  margin-top: 16px;
}
</style>

<script>
import BaseIcon from './BaseIcon'

export default {
  name: 'BaseButton',
  components: {
    BaseIcon
  },
  props: {
    color: {
      type: String,
      default: 'light',
      validator: value => ['primary', 'success', 'danger', 'grey', 'light'].includes(value)
    },
    label: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'default',
      validator: value => ['x-small', 'small', 'default', 'large', 'x-large'].includes(value)
    },
    icon: {
      type: String,
      default: ''
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <button
    :class="[
      'button',
      color && `button--${color}`,
      size && `button--${size}`,
      rounded && iconOnly && 'button--rounded',
    ]"
    v-on="$listeners"
  >
    <BaseIcon
      v-if="icon"
      :type="icon"
      aria-hidden="true"
      class="button__icon chromatic-ignore"
      :alt="label"
      :title="label"
    />
    <span
      class="button__label"
      :class="{ 'sr-only': iconOnly }"
    >{{ label }}</span>
  </button>
</template>

<style lang="scss" scoped>
/* stylelint-disable function-no-unknown */
@mixin size($size-name, $padding, $size, $font-size) {
  $selector: if($size-name == "default", ".button", ".button.button--#{$size-name}");
  #{$selector} {
    font-size: $font-size;
    height: $size;

    .button__label {
      display: inline-block;
      padding: $padding;
    }

    &.button--rounded {
      flex: 0 0 $size;
      width: $size;
    }
  }
}

.button {
  align-items: center;
  appearance: none;
  border: none;
  border-radius: $border-radius;
  color: get-color(white);
  cursor: pointer;
  display: inline-flex;
  flex-flow: nowrap;
  justify-content: center;

  &:disabled {
    cursor: default;
  }

  &--rounded {
    border-radius: 100%;
  }

  @each $type, $value in $base {
    $theme: if($type == light or $type == grey or $type == danger, dark, light);
    $color: if($type == light, get-color(default-darker), get-color(light));

    &--#{"" + $type} {
      background-color: get-color($type);
      color: $color;

      &:not(:disabled):hover {
        background-color: get-color(#{$type}-#{$theme});
      }

      &:not(:disabled):active {
        background-color: get-color(#{$type}-#{$theme}er);
      }
    }
  }

  &__icon {
    height: 1em;
    width: 1em;
  }
}

@include size("x-small", 0.2rem, 1.2rem, 0.7rem);
@include size("small", 0.2rem, 1.6rem, 0.8rem);
@include size("default", 0.6rem, 2rem, 1rem);
@include size("large", 0.8rem, 2.4rem, 1.2rem);
@include size("x-large", 1rem, 2.8rem, 1.4rem);

// Allow screen readers indentify the button label
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
</style>

<script>
import DataSource from './data-source'

import BaseCard from '../BaseCard'

export const dataSource = new DataSource()

export default {
  name: 'DamageDetection',
  components: {
    BaseCard
  },
  props: {
    appraisal: {
      required: true,
      type: [Number, String]
    },
    env: {
      type: String,
      default: null // it defaults to production when not specified
    }
  },
  data: () => ({
    reset: false
  }),
  computed: {
    dataSource: () => dataSource,
    baseUrl: ({ env }) => {
      const prefix = ['dev', 'stage'].includes(env) ? `${env}.` : ''
      return `https://${prefix}my.max.auto`
    },
    updatedAt: vm => vm.report?.updatedAt && new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
      month: 'short',
      timeZone: 'UTC',
      year: 'numeric'
    }).format(new Date(vm.report.updatedAt))
      .replace(/AM/g, 'a.m.').replace(/PM/g, 'p.m.'),
    photosUrl: vm => vm.baseUrl + `/appraisals/${vm.appraisal}/photos/appraisal`,
    report: vm => vm.dataSource.report,
    reportUrl: vm => vm.report?.reportUrl,
    reportView: vm => vm.state.inProgress
      ? {
          title: 'in Progress',
          label: 'Started',
          icon: require('../../icons/warning.svg').default
        }
      : {
          title: 'Completed',
          label: 'Completed',
          icon: require('../../icons/complete.svg').default
        },
    state: vm => ({
      loading: vm.dataSource.loading,
      error: vm.dataSource.error,
      empty: vm.report === null,
      inProgress: vm.report?.status === 'NOT_STARTED'
    })
  },
  watch: {
    appraisal: {
      immediate: true,
      handler () {
        this.dataSource.load(this.appraisal)
      }
    }
  },
  methods: {
    toggleReset () {
      this.reset = !this.reset
    },
    resetReport () {
      this.dataSource.reset()
      this.reset = false
    }
  }
}
</script>

<template>
  <BaseCard class="damage-detection">
    <section
      v-if="state.loading"
      class="damage-detection__wrapper"
    >
      <svg
        role="status"
        aria-live="polite"
        aria-busy="true"
        aria-label="Loading"
        data-chromatic="ignore"
        class="damage-detection__loading"
        viewBox="0 0 50 50"
      >
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        />
      </svg>
    </section>
    <section
      v-else-if="state.error"
      class="damage-detection__wrapper"
    >
      <span>Damage Detection not available for this vehicle</span>
    </section>
    <section
      v-else-if="state.empty"
      class="damage-detection__wrapper"
    >
      <span
        class="damage-detection__icon"
        aria-disabled="true"
      >
        <img
          :src="require(`../../icons/mobile-download.svg`).default"
          height="20"
          width="24"
          alt="Use the ACV MAX mobile app"
        >
      </span>
      <span>Use the <strong>ACV MAX mobile app</strong> to capture damage photos.</span>
    </section>
    <section
      v-else-if="reset"
      class="damage-detection__wrapper"
    >
      <span class="text--title">
        Reset Damage Detection?
      </span>
      <span>Resetting will erase current progress and initiate a new damage detection report.</span>
      <div class="reset__actions">
        <button
          ref="cancelButton"
          class="button button--ghost"
          @click="toggleReset"
        >
          Cancel
        </button>
        <button
          ref="confirmButton"
          class="button button--expanded button--danger"
          @click="resetReport"
        >
          Yes, Reset Damage
        </button>
      </div>
    </section>
    <div
      v-else
      class="damage-detection__wrapper damage-detection__wrapper--detail"
    >
      <section class="damage-detection__details">
        <div class="damage-detection__info">
          <div
            class="info__icon"
            :aria-disabled="true"
          >
            <img
              :alt="reportView.title"
              :src="reportView.icon"
              width="20"
              height="20"
            >
          </div>
          <div>
            <strong class="text--title">Damage Detection {{ reportView.title }}</strong><br>
            <span class="text--muted">{{ reportView.label }}: {{ updatedAt }}</span>
          </div>
        </div>
        <div
          v-if="state.inProgress"
          class="text--right"
        >
          Use the <strong>ACV MAX mobile app</strong> to capture damage photos.
        </div>
      </section>
      <section class="damage-detection__actions">
        <button
          ref="resetButton"
          class="button button--ghost"
          title="Reset"
          @click="toggleReset"
        >
          <img
            :src="require(`../../icons/trash-can.svg`).default"
            height="14"
            width="12"
            alt="Reset"
            aria-disabled="true"
          >
        </button>
        <div class="actions__aside">
          <a
            class="button button--ghost button--expanded"
            :href="photosUrl"
            target="_blank"
          >
            Damage Photos
          </a>
          <a
            :aria-disabled="state.inProgress"
            class="button button--primary button--expanded"
            :class="{ 'button--disabled': state.inProgress }"
            :href="reportUrl"
            target="_blank"
          >
            Damage Report
          </a>
        </div>
      </section>
    </div>
  </BaseCard>
</template>

<style lang="scss">
/* stylelint-disable function-no-unknown */
.damage-detection {
  font-size: 0.9rem;
  padding: 1.2rem 1.2rem 1.2rem 0.4rem;

  .text {
    &--title {
      display: inline-block;
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      white-space: nowrap;
    }

    &--muted {
      color: get-color(default-dark);
    }

    &--right {
      text-align: right;
    }
  }

  .button {
    align-items: center;
    appearance: none;
    border: 1px solid transparent;
    border-radius: 10rem;
    color: get-color(white);
    cursor: pointer;
    display: inline-flex;
    font-size: 0.9rem;
    justify-content: center;
    padding: 0.5rem 1rem;
    text-decoration: none;
    white-space: nowrap;

    &--primary {
      background-color: get-color(primary);
      color: get-color(white);

      &:hover {
        background-color: get-color(primary-light);
      }
    }

    &--disabled {
      cursor: default;
      pointer-events: none;

      &.button--primary {
        background-color: get-color(primary-lighter);
      }
    }

    &--ghost {
      background-color: transparent;
      border-color: get-color(primary);
      color: get-color(primary);

      &:hover {
        background-color: get-color(default-lighter);
      }
    }

    &--danger {
      background-color: get-color(danger);

      &:hover {
        background-color: get-color(danger-dark);
      }
    }

    &--expanded {
      max-width: 16.25rem;
      width: 100%;
    }
  }

  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100px;
    text-align: center;
    width: 100%;

    &--detail {
      align-items: initial;
      justify-content: space-between;
      text-align: left;
    }
  }

  &__icon {
    align-items: center;
    background-color: get-color(default-lighter);
    border-radius: 100%;
    display: inline-flex;
    height: 3rem;
    justify-content: center;
    margin-bottom: 0.5rem;
    width: 3rem;
  }

  &__details {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__info {
    display: flex;
    margin-bottom: 0.5rem;

    .info__icon {
      margin-top: 0.2rem;
      text-align: center;
      width: 2.5rem;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    padding-left: 2.5rem;

    .actions__aside {
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
      width: 80%;
    }
  }

  .reset__actions {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
  }

  &__loading {
    animation: rotate 2s linear infinite;
    height: 2.25rem;
    margin-bottom: 1rem;
    width: 2.25rem;

    & .path {
      animation: spinner 1.5s ease-in-out infinite;
      stroke: get-color(primary);
      stroke-linecap: round;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }

    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}
</style>

<script>
const SIZES = {
  'x-small': 0.6,
  small: 0.8,
  default: 1,
  large: 1.2,
  'x-large': 1.4
}

export default {
  name: 'BaseIcon',
  functional: true,
  props: {
    type: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: '',
      validator: value => ['', ...Object.keys(SIZES)].includes(value)
    }
  },
  render (h, { data, props }) {
    const svgPath = require(`../icons/${props.type}.svg`).default
    const iconSize = `${SIZES[props.size]}rem`
    return svgPath &&
      h('img', {
        ...props.size && {
          style: {
            width: iconSize,
            height: iconSize
          }
        },
        class: data.staticClass,
        attrs: {
          ...data.attrs,
          src: svgPath
        }
      })
  }
}
</script>

<script>
import { currency, currencyToInt } from '@maxsystems/ui/vue/filters'

import BaseButton from '../BaseButton'

export const STATES = {
  LOADING: 'LOADING',
  EDITING: 'EDITING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

export default {
  name: 'OfferField',
  components: {
    BaseButton
  },
  inject: ['dataSource'],
  props: {
    initialOffer: {
      type: [String, Number],
      default: '0'
    },
    currency: {
      type: String,
      default: 'USD'
    },
    appraisal: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      offer: this.initialOffer,
      showError: false,
      state: null
    }
  },
  computed: {
    actions () {
      return {
        [STATES.SUCCESS]: {
          ref: 'success',
          label: 'Offer updated',
          color: 'success',
          icon: 'success',
          disabled: true
        },
        [STATES.ERROR]: {
          ref: 'error',
          label: 'Error',
          color: 'danger',
          icon: 'error',
          class: 'action action--error',
          on: {
            focus: () => { this.showError = true }
          }
        },
        [STATES.LOADING]: {
          ref: 'loading',
          label: 'Saving...',
          color: 'grey',
          icon: 'loading',
          disabled: true
        },
        [STATES.EDITING]: {
          ref: 'save',
          label: 'Save',
          color: 'primary',
          icon: 'check',
          on: {
            click: this.save
          }
        }
      }
    },
    prefix () { return currency(0, { currency: this.currency }).replace(/[0-9]/g, '') },
    preventCancel () { return this.state === STATES.LOADING || this.state === STATES.SUCCESS },
    hasError () { return this.state === STATES.ERROR }
  },
  methods: {
    input ({ target }) {
      const initialPosition = target.value.length - target.selectionStart
      const parsedValue = currencyToInt(target.value)
      this.offer = currency(parsedValue, { currency: this.currency, style: 'decimal' })
      const finalPosition = this.offer.length - initialPosition

      this.$nextTick(() => {
        this.$refs.field.selectionEnd = finalPosition
      })

      this.state = this.offer !== this.initialOffer ? STATES.EDITING : null
    },
    cancel (event) {
      event.preventDefault()

      this.state = null

      this.offer = this.initialOffer
      this.showError = false
      this.$refs.field.focus()
    },
    async save (event) {
      event.preventDefault()

      if (this.state !== STATES.EDITING && this.state !== STATES.ERROR) return

      this.showError = false
      this.state = STATES.LOADING

      const parsedOffer = currencyToInt(this.offer)
      const input = {
        id: this.appraisal,
        amount: parsedOffer,
        currency: this.currency
      }

      try {
        await this.dataSource.updateAppraisalOffer(input)
        this.state = STATES.SUCCESS
        setTimeout(() => { this.state = null }, 3000)
      } catch {
        this.state = STATES.ERROR
      }
    }
  }
}
</script>

<template>
  <div
    ref="offer"
    class="offer"
    @keydown.esc.stop="cancel"
    @keydown.enter.stop="save"
  >
    <div class="offer__input">
      <span class="offer__prefix">{{ prefix }}</span>
      <input
        ref="field"
        v-model="offer"
        type="text"
        placeholder="0,00"
        class="offer__field"
        maxlength="7"
        :disabled="preventCancel"
        @input="input"
      >
      <BaseButton
        v-if="state"
        ref="cancel"
        label="Cancel Offer"
        color="light"
        icon="cancel"
        size="small"
        icon-only
        rounded
        :style="{
          visibility: preventCancel ? 'hidden' : 'visible'
        }"
        :disabled="preventCancel"
        @click="cancel"
        @focus="showError = false"
      />
    </div>
    <BaseButton
      v-if="state"
      size="large"
      class="action"
      v-bind="actions[state]"
      icon-only
      v-on="actions[state].on"
    />
    <div
      v-if="hasError"
      ref="error-message"
      class="offer-error"
      :class="{ 'offer-error--visible': showError }"
    >
      <div
        class="offer-error__heading"
      >
        <strong class="offer-error__title">Error saving</strong>
        <BaseButton
          ref="try-again"
          label="Try again"
          color="danger"
          icon="retry"
          size="small"
          icon-only
          rounded
          @click="save"
          @blur="showError = false"
        />
      </div>
      <div class="offer-error__body">
        An unexpected error occurred. Please try again or contact support if the problem persists.
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* stylelint-disable function-no-unknown */
.offer {
  display: inline-flex;
  font-family: system-ui;
  position: relative;

  &__prefix {
    color: get-color(default);
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0 0.5rem;
  }

  &__input {
    align-items: center;
    background-color: get-color(white);
    border: get-color(border-color) 1px solid;
    border-radius: $border-radius;
    display: flex;
    padding-right: 0.2rem;
    position: relative;
    width: auto;
    z-index: 2;

    &:focus-within {
      border-color: get-color(default-darker);
    }
  }

  &__field {
    appearance: none;
    border: none;
    border-radius: $border-radius;
    font-size: 1.25rem;
    height: calc(#{$input-size} - 2px);
    margin: 0;
    outline: none;
    padding: 0 0.2rem 0 0;
    text-align: right;
    width: 4.8rem;

    &:disabled {
      background-color: transparent;
    }
  }

  .action {
    left: -0.4rem;
    padding-left: 0.6rem;
    position: relative;

    &--error {
      &:hover + div {
        display: block;
      }
    }
  }

  &-error {
    display: none;
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 0.4rem);
    z-index: 3;

    &--visible,
    &:hover {
      display: block;
    }

    &__heading {
      align-items: center;
      background-color: get-color(danger);
      border-radius: $border-radius $border-radius 0 0;
      box-sizing: border-box;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      min-height: $input-size;
      padding: 0.3rem;
      width: 100%;
    }

    &__title {
      color: get-color(white);
    }

    &__body {
      background-color: get-color(white);
      border: get-color(border-color) 1px solid;
      border-radius: 0 0 $border-radius $border-radius;
      border-top: none;
      box-sizing: border-box;
      padding: 0.5rem;
      width: 100%;
    }
  }
}
</style>

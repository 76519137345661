<script>
export default {
  functional: true,
  render (h, context) {
    return h('div', {
      class: ['appraisal-widgets--card', context.data.staticClass]
    }, context.children)
  }
}
</script>

<style lang="scss">
.appraisal-widgets--card {
  background-color: #FFF;
  border-radius: 5px;
  display: flex;
  font-family: system-ui;
}
</style>

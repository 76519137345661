import Vue from 'vue'
import { withQuery, auth, authData, client } from '@maxsystems/client'

import getAppraisal from './queries/getAppraisal.gql'
import getACVMaxRetail from './queries/getACVMaxRetail.gql'
import getGuarantee from './queries/getGuarantee.gql'
import updateAppraisal from './queries/updateAppraisal.gql'
import getInventoryBookouts from './queries/getInventoryBookouts.gql'

export default new Vue({
  data: () => ({
    authData,
    actions: {
      fetchAppraisals: withQuery(getAppraisal),
      fetchInventory: withQuery(getInventoryBookouts),
      fetchGuarantee: withQuery(getGuarantee),
      fetchRetail: withQuery(getACVMaxRetail),
      updateAppraisal: withQuery(updateAppraisal)
    },
    isAuthenticating: false,
    values: null,
    guaranteeValue: undefined,
    id: null, // Appraisal Id or Inventory Id
    type: null
  }),

  computed: {
    amount: ({ values }) => values?.offer?.value?.amount,
    guarantee: vm => ({
      isEnabled: vm.values?.dealer.showACVGuarantee,
      // Handled only for Appraisal since Guarantee is fetched after Wholesale data.
      // Inventory values are fetched at once so the loading is the same for the entire widget.
      isLoading: vm.type === 'appraisal' && vm.actions.fetchGuarantee.isPending(),
      value: vm.guaranteeValue
    }),
    isLoadingRetail: ({ actions, isAuthenticating, type }) => {
      if (type === 'inventory') return isAuthenticating || actions.fetchInventory.isPending()
      return actions.fetchAppraisals.isPending() || actions.fetchRetail.isPending()
    },
    isLoadingWholesale: ({ actions, isAuthenticating, type }) => {
      if (type === 'inventory') return isAuthenticating || actions.fetchInventory.isPending()
      return actions.fetchAppraisals.isPending()
    },
    isLoggedIn: ({ authData: { isLoggedIn } }) => isLoggedIn,
    profile: ({ values }) => values?.customer?.profile,
    fetchingAppraisal: ({ actions }) => actions.fetchAppraisals.isPending()
  },

  watch: {
    id (value) {
      if (this.type === 'inventory') {
        this.loadInventory(value)
        return
      }
      this.load(value)
    }
  },

  methods: {
    async isAuthenticated () {
      if (this.isLoggedIn) return true

      Vue.use(auth)
      auth.setup()

      return new Promise(resolve => {
        this.$watch('isLoggedIn', isLoggedIn => {
          if (!isLoggedIn) return
          resolve(true)
        }, { immediate: true })
      })
    },

    setEnv (env) {
      if (env) client.config({ env })
    },

    setId (id) {
      this.id = id
    },

    setType (type) {
      this.type = type
    },

    async fetchGuarantee () {
      // Errors are handled by presenting "Not Available" ui state
      const { data } = await this.actions.fetchGuarantee.fetch({ appraisalId: this.id })
      this.guaranteeValue = data.appraisal.guarantee?.adjusted.amount || null
    },

    async fetchRetail () {
      const { id } = this

      // wait for appraisal to be loaded
      await Promise.all(Object.values(this.actions.fetchAppraisals.pending).map(({ req }) => req))

      if (!this.values) return

      const { data } = await this.actions.fetchRetail.fetch({ appraisalId: id })
      this.$set(this.values, 'acvMaxRetailValue', data.appraisal.acvMaxRetailValue)
    },

    async load (id) {
      this.values = null

      try {
        const { data } = await this.actions.fetchAppraisals.fetch({ appraisalId: id })
        this.values = data?.appraisal || null
      } catch {} // errors are handled by presenting "Not Available" ui state

      if (this.guarantee.isEnabled) this.fetchGuarantee()
    },

    /**
     * Fetch Bookout data for Inventory (authenticated), parse and
     * set the values to match the same keys as the Appraisal data.
     * @param {*} id - Inventory Id
     */
    async loadInventory (id) {
      this.isAuthenticating = true
      await this.isAuthenticated()
      this.isAuthenticating = false
      this.values = null
      this.guaranteeValue = null

      try {
        const { data } = await this.actions.fetchInventory.fetch({ inventoryId: id })
        const values = this.parseBookouts(data?.inventoryBookOut?.[0].bookOutData)

        this.guaranteeValue = values['acv-guarantee']
        this.values = {
          ...data?.inventory,
          valuation: values['wholesale-offer']
            ? {
                adjusted: { amount: values['wholesale-offer'] },
                highValue: { amount: values['wholesale-high-value'] },
                lowValue: { amount: values['wholesale-low-value'] }
              }
            : null,
          acvMaxRetailValue: values['retail-estimate']
            ? {
                acvEstimate: values['retail-estimate'],
                highValue: values['retail-high-value'],
                lowValue: values['retail-low-value']
              }
            : null
        }
      } catch { }
    },

    /**
     * Convert bookout data to key-value pairs
     * @param {Array} bookOutData - List of all book values from ACV
     * @returns {Object} - Object with all book values parsed as specified keys
     */
    parseBookouts (bookOutData = []) {
      return bookOutData.reduce((acc, value) => {
        const key = value.condition?.toLowerCase().replace(/ /g, '-')
        acc[key] = value?.valuations?.find(val => val.desc === 'Final Value')?.value
        return acc
      }, {})
    },

    async updateAppraisalOffer (offerInputs) {
      // make sure we are authenticated
      await this.isAuthenticated()

      const input = { updateOffer: offerInputs }
      const { data, errors } = await this.actions.updateAppraisal.fetch({ input })

      if (errors?.length) throw new Error(errors[0].message || errors[0].extensions.code)

      // update local cache
      this.values.offer.value = data.updateAppraisal[0].appraisal.offer.value
    }
  }
})

<script>
export default {
  name: 'LoadingSkeleton',
  props: {
    width: {
      type: [Number, String],
      default: 120
    },
    height: {
      type: [Number, String],
      default: 32
    }
  },
  computed: {
    viewBox: vm => `0 0 ${vm.width} ${vm.height}`
  }
}
</script>

<template>
  <svg
    aria-disabled="true"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    preserveAspectRatio="none"
  >
    <rect
      width="100%"
      height="100%"
      rx="4"
      ry="4"
      fill="#E0E0E0"
    >
      <animate
        attributeName="fill"
        values="#E0E0E0;#F0F0F0;#E0E0E0"
        dur="1.5s"
        repeatCount="indefinite"
      />
    </rect>
  </svg>
</template>

<script>
import dataSource from '../../data-source'
import { currency } from '@maxsystems/ui/vue/filters'

import BaseCard from '../BaseCard.vue'
import LoadingSkeleton from '../LoadingSkeleton.vue'

export default {
  name: 'ACVRetail',
  filters: {
    currency
  },
  components: {
    BaseCard,
    LoadingSkeleton
  },
  props: {
    /* Appraisal or Inventory Id */
    id: {
      required: true,
      type: [Number, String]
    },
    env: {
      type: String,
      default: null // it defaults to production when not specified
    },
    type: {
      type: String,
      default: 'appraisal',
      validator: value => ['appraisal', 'inventory'].includes(value)
    }
  },
  computed: {
    dataSource: () => dataSource,
    itemData: vm => vm.dataSource.values,
    retailData: vm => vm.itemData?.acvMaxRetailValue,
    lowAmount: vm => vm.retailData?.lowValue,
    highAmount: vm => vm.retailData?.highValue,
    retailAmount: vm => vm.retailData?.acvEstimate,
    mileage: vm => vm.itemData?.vehicle?.odometer?.value,
    isLoading: vm => vm.dataSource.isLoadingRetail
  },
  async mounted () {
    dataSource.setEnv(this.env)
    dataSource.setType(this.type)
    dataSource.setId(this.id)

    /**
     * Inventory (All values) or Wholesale Appraisal are loaded by the datasource
     * when defining the type and id. For Appraisals, the Retail is loaded afterwards
     * to prevent VIN Decoder issues on ACV API made by GraphQL.
     */
    if (this.type === 'appraisal') {
      await this.$nextTick() // wait for the watcher in the data source to load the appraisal
      dataSource.fetchRetail()
    }
  }
}
</script>

<template>
  <BaseCard class="acv-retail">
    <div class="acv-retail__header">
      <img
        :src="require('../ACVMaxLogo.svg').default"
        alt="ACV MAX Logo"
        width="72"
        height="32"
      >
      <span class="acv-retail__title">Retail</span>
    </div>
    <div
      v-if="isLoading"
      class="acv-retail__content acv-retail__content--loading"
      aria-busy="true"
      aria-live="polite"
      role="img"
      aria-label="Loading"
    >
      <div>
        <LoadingSkeleton
          width="90"
          height="18"
        />
        <div />
        <LoadingSkeleton
          width="90"
          height="18"
        />
      </div>
      <div>
        <LoadingSkeleton
          width="90"
          height="30"
        />
        <LoadingSkeleton
          width="90"
          height="30"
        />
        <LoadingSkeleton
          width="90"
          height="30"
        />
      </div>
    </div>
    <div
      v-else-if="itemData && !mileage"
      class="acv-retail__content acv-retail__content--empty"
    >
      Update mileage to get an ACV MAX Retail Value
    </div>
    <div
      v-else-if="!retailData"
      class="acv-retail__content acv-retail__content--empty"
    >
      <span>Value Not Available for this Vehicle</span>
    </div>
    <div
      v-else
      class="acv-retail__content"
    >
      <div class="acv-retail__range">
        <div class="acv-retail__range-title">
          Range
        </div>
        <div
          class="acv-retail__range-value"
        >
          {{ lowAmount || '-' | currency }} - {{ highAmount || '-' | currency }}
        </div>
      </div>
      <div class="acv-retail__valuation">
        {{ retailAmount || '' | currency }}
      </div>
    </div>
  </BaseCard>
</template>

<style lang="scss">
.acv-retail {
  flex-flow: column;

  &__header {
    align-items: center;
    background-color: #F8F9FC;
    border-radius: 5px 5px 0 0;
    display: flex;
    height: 48px;
    padding: 0 16px;
  }

  &__title {
    color: #212121;
    margin: 3px 6px 0 12px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    margin: 16px;
    min-height: 80px;

    &--empty {
      align-items: center;
      color: #000;
      justify-content: center;
      margin: 10px;

      span {
        color: #55575C;
      }
    }

    &--loading {
      flex-direction: column;
      margin: 16px;
      padding-top: 10px;

      div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
      }
    }
  }

  &__range {
    width: 50%;
  }

  &__range-title {
    color: #55575C;
    font-size: 16px;
    margin-bottom: 6px;
  }

  &__range-value {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }

  &__valuation {
    align-items: flex-end;
    color: #212121;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 600;
    text-align: right;
    width: 50%;
  }
}
</style>
